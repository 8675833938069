import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Datas from './data/ProjectData.js'

import Nav from './components/nav/Nav.jsx'
import Footer from './components/footer/Footer.jsx'
import Home from './components/home/Home.jsx'
import CaseStudy from './components/caseStudy/CaseStudy.jsx'
import Project from './components/caseStudy/project/Project.jsx'
import Reel from './components/home/reel/Reel.jsx'
import About from './components/home/about/About.jsx'
import Preloader from './components/general/Preloader/Preloader.jsx'


 const App = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    return (

        <>
        {
            loading ?

            <Preloader loading={loading} />

            :

            <Router>
                <Nav />
                <main>
                    <Routes>
                        <Route exact path='/' element={<Home Datas={Datas}/>} />
                        <Route path='/work' element={<CaseStudy Datas={Datas} />} /> 
                        <Route path='/work/:url' element={<Project Datas={Datas}/>} /> 
                        <Route path='/reel' element={<Reel />} />
                        <Route path='/about' element={<About />} />                  
                    </Routes>
                </main>
                <Footer />
            </Router>
        }

        </>
    )
}

export default App