import React, { useState, useEffect, useRef } from 'react'
import { ImMusic } from 'react-icons/im'
import { MdOutlineMusicOff } from 'react-icons/md'

import './play.css'
import { PlayVideo, Butterflies, BgAudio } from '../../../data/HomeData.js'

import BTN from '../../general/Button/BTN.jsx'
import Socials from '../../general/Socials.jsx'


const Play = () => {

  //-------------------------Background Audio---------------------------//
  const [isPlaying, setIsPlaying] = useState(false)

  const audioRef = useRef(new Audio(BgAudio))

  const audioPlay = () => {
    setIsPlaying(true)
    audioRef.current.play()
    audioRef.current.loop = true
  }

  const audioPause = () => {
    setIsPlaying(false);
    audioRef.current.pause()
  }

  //-------------------------Catch---------------------------//
  const [action, setAction] = useState(null)

  useEffect(() => {
    setAction(PlayVideo.ready)
    setTimeout(() => {
      setAction(PlayVideo.loop)
    }, 500)
  }, [])

  const handleClick = () => {
    setAction(PlayVideo.catch)
    audioPlay()
    setTimeout(() => {
      setAction(PlayVideo.loop)
    }, 4600)
  }

  //-------------------------Return---------------------------//
   
  return (
    <section className='play firstSection' id='play' >

      <img className='butterflies' src={Butterflies} alt='butterflies' />

      <Socials />

      <div className='play_container'>
        <img className='play_gifs' src={action} alt='play_gifs' />
        <BTN text="Catch" handleClick={handleClick} />
      </div>
      
      <div className='function'>
        <a className='social_icon' onClick={isPlaying? audioPause : audioPlay} >
          { isPlaying ? <ImMusic /> : <MdOutlineMusicOff /> }
        </a>
        <a className='email' >sisili.tv@gmail.com</a>
      </div>

    </section>
  )
}

export default Play