const Datas = [
    {
        id: 0,
        title: "Pfizer",
        url: "Pfizer",
        homeThumbnail: require('../assets/work/Pfizer/thumbnail_square.png'),
        thumbnail: null,
        header: [],
        lockup: null,
        credits: [],
        video: null,
        videoV: null,
        subTitle1: null,
        images: [],
        images4x3: [],
        subTitle2: null,
        images4x3x2:[],
        videoF: null,
        thankYou: null
    },
    {
        id: 1,
        title: "Camera Ready Kutz",
        url: "CameraReadyKutz",
        homeThumbnail: require('../assets/work/CameraReadyKutz/thumbnail_square.png'),
        thumbnail: require('../assets/work/CameraReadyKutz/thumbnail_4x3.png'),
        header: [require('../assets/work/CameraReadyKutz/header1.png'), require('../assets/work/CameraReadyKutz/header2.png')],
        lockup: require('../assets/work/CameraReadyKutz/lockup.gif'),
        credits: [
                  "Client: Grow with Google",
                  "Agency: M Booth",
                  "Creative Director: Linh Pham",
                  "Art Director: Taylor Baldry",
                  "Project Management: Kyle McEwen",
                  "Illustration & Animation: Sisi Li"
                 ],
        videoV: "https://player.vimeo.com/video/643093492?h=3ca3dddcb8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
        id: 2,
        title: "Google Cloud Next '21",
        url: "GoogleCloudNext21",
        homeThumbnail: require('../assets/work/GoogleCloudNext21/thumbnail_square.png'),
        thumbnail: require('../assets/work/GoogleCloudNext21/thumbnail_4x3.png'),
        header: [require('../assets/work/GoogleCloudNext21/header.gif')],
        credits: [
                 "Client: Google Cloud",
                 "Agency: M Booth",
                 "Creative Director: Linh Pham",
                 "Art Director: Pitu Sanchez",
                 "Project Management: Kyle McEwen",
                 "3D Design & Animation: Sisi Li"
                 ],
        video: "https://player.vimeo.com/video/718916864?h=b149455123&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        images4x3: [
                require('../assets/work/GoogleCloudNext21/Learn.gif'),
                require('../assets/work/GoogleCloudNext21/Engage.gif'),
                require('../assets/work/GoogleCloudNext21/Solve.gif')
                ],
        images: [
                require('../assets/work/GoogleCloudNext21/NEX-Multiple-Speakers-template.png'),
                require('../assets/work/GoogleCloudNext21/NEX-GPT-Listicle-Template.png')
                ],
        thankYou: [
                  require('../assets/work/GoogleCloudNext21/GIPHY2.gif')
                  ]
    },
    {
        id: 3,
        title: "Safer Internet Day Quest",
        url: "SaferInternetDayQuest",
        homeThumbnail: require('../assets/work/SaferInternetDayQuest/thumbnail_square.png'),
        thumbnail: require('../assets/work/SaferInternetDayQuest/thumbnail_4x3.png'),
        header: [require('../assets/work/SaferInternetDayQuest/header.gif')],
        lockup: require('../assets/work/SaferInternetDayQuest/lockup.gif'),
        credits: [
                 "Client: Google Education - Be Internet Awesome",
                 "Agency: M Booth",
                 "Creative Director: Linh Pham",
                 "Art Director: Taylor Baldry​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "Lead Design: Sisi Li",
                 "3D Design & Animation: Sisi Li / Andie Villanueva"
                 ],
        subTitle1: "Visual Assets",
        images: [
            require('../assets/work/SaferInternetDayQuest/sticker1.png'),
            require('../assets/work/SaferInternetDayQuest/sticker2.png')
            ],
        subTitle2: "Quest Game",
        images2:[
                require('../assets/work/SaferInternetDayQuest/QuestG1.png'),
                require('../assets/work/SaferInternetDayQuest/QuestG2.png'),
                require('../assets/work/SaferInternetDayQuest/QuestG3.png'),
                require('../assets/work/SaferInternetDayQuest/Shot1_gif2.gif'),
                ]
    },
    {
        id: 4,
        title: "Find Harmony This Season",
        url: "FindHarmonyThisSeason",
        homeThumbnail: require('../assets/work/FindHarmonyThisSeason/thumbnail_square.gif'),
        thumbnail: require('../assets/work/FindHarmonyThisSeason/thumbnail_4x3.gif'),
        header: [require('../assets/work/FindHarmonyThisSeason/header.gif')],
        credits: [
                 "Agency: M Booth",
                 "Creative Director: Michele Mason",
                 "Art Director: Taylor Baldry​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "Illustration: Frances Matos",
                 "Animation: Sisi Li"
                 ],
        images4x3: [
                 require('../assets/work/FindHarmonyThisSeason/Margi.gif'), 
                 require('../assets/work/FindHarmonyThisSeason/tub_man.gif'),
                 require('../assets/work/FindHarmonyThisSeason/drummer.gif'),
                 require('../assets/work/FindHarmonyThisSeason/laptoplady.gif'),
                 require('../assets/work/FindHarmonyThisSeason/tall_cold_brew.gif'),
                 require('../assets/work/FindHarmonyThisSeason/photographer.gif'),
                 require('../assets/work/FindHarmonyThisSeason/trumpet_player.gif'),
                 require('../assets/work/FindHarmonyThisSeason/sign_lady.gif')        
                 ],
        videoF: "https://player.vimeo.com/video/738859204?h=427a81b362&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
    },
    {
        id: 5,
        title: "San Francisco Bay",
        url: "SanFranciscoBay",
        homeThumbnail: require('../assets/work/SanFranciscoBay/thumbnail_square.png'),
        thumbnail: require('../assets/work/SanFranciscoBay/thumbnail_4x3.png'),
        header: [require('../assets/work/SanFranciscoBay/header.png')],
        credits: ["Personal 3D Projects Collection"],
        images: [
                   require('../assets/work/SanFranciscoBay/ClayApple.jpg'),
                   require('../assets/work/SanFranciscoBay/vase.png'),
                   require('../assets/work/SanFranciscoBay/AncientChineseDoor.png'),
                   require('../assets/work/SanFranciscoBay/valetineheart.png'),
                   require('../assets/work/SanFranciscoBay/cloud.gif')
                   ]
    },
    {
        id: 6,
        title: "Roborock Q7 MAX",
        url: "RoborockQ7MAX",
        homeThumbnail: require('../assets/work/RoborockQ7MAX/thumbnail_square.png'),
        thumbnail: require('../assets/work/RoborockQ7MAX/thumbnail_4x3.png'),
        header: [require('../assets/work/RoborockQ7MAX/header.png')],
        credits: [
                 "Client: Roborock",
                 "Agency: AOSUM",
                 "Production: The oriental vision",
                 "Art Director: Taylor Baldry​​​​​​​",
                 "Producer: Grace Gu/Nan Li",
                 "Director: LUYI",
                 "VFX & 3D Motion Designer: Sisi Li"
                ],
        video: "https://player.vimeo.com/video/706400090?h=1dbd883be4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        subTitle1: "Sytle Frames",
        images: [
                require('../assets/work/RoborockQ7MAX/VFX6+VFX7 (0063).png'),
                require('../assets/work/RoborockQ7MAX/VFX6+VFX7 (0240).png'),
                require('../assets/work/RoborockQ7MAX/VFX4_W (0119).png'),
                require('../assets/work/RoborockQ7MAX/VFX3 (0000).png'),
                require('../assets/work/RoborockQ7MAX/VFX2 (0089).png'),
                require('../assets/work/RoborockQ7MAX/VFX8 (0085).png'),
                require('../assets/work/RoborockQ7MAX/VFX9_W (0013).png')
                ]
    },
    {
        id: 7,
        title: "All Creatures Great & Small",
        url: "AllCreaturesGreatSmall",
        homeThumbnail: require('../assets/work/AllCreaturesGreat&Small/thumbnail_square.png'),
        thumbnail: require('../assets/work/AllCreaturesGreat&Small/thumbnail.png'),
        header: [require('../assets/work/AllCreaturesGreat&Small/thumbnail.png')],
        credits:["Personal Illustration Collection"],
        images: [
                require('../assets/work/AllCreaturesGreat&Small/grapes.png'),
                require('../assets/work/AllCreaturesGreat&Small/hotpot.png'),
                require('../assets/work/AllCreaturesGreat&Small/sun.png'),
                require('../assets/work/AllCreaturesGreat&Small/ocean.png'),
                require('../assets/work/AllCreaturesGreat&Small/sketch.png'),
                ]
    },
    {
        id: 8,
        title: "Introduce Google Ad Mobster",
        url: "IntroduceGoogleAdMobster",
        homeThumbnail: require('../assets/work/IntroduceGoogleAdMobster/thumbnail_square.gif'),
        thumbnail: require('../assets/work/IntroduceGoogleAdMobster/014_GAM_Admobster_Carousel_JOY_SL.gif'),
        header: [require('../assets/work/IntroduceGoogleAdMobster/header.gif')],
        credits: [
                 "Client: Google",
                 "Agency: M Booth",
                 "Art Director: Howard Herrarte​​​​​​​",
                 "Designer & Animator: Sisi Li",
                 "",
                 "",
                 "Character design by Google"
                 ],
        subTitle1: "Characters",
        images4x3: [
                   require('../assets/work/IntroduceGoogleAdMobster/014_GAM_Admobster_Carousel_JOY_SL.gif'),
                   require('../assets/work/IntroduceGoogleAdMobster/014_GAM_Admobster_Carousel_Max_SL.gif'),
                   require('../assets/work/IntroduceGoogleAdMobster/014_GAM_Admobster_Carousel_Skip_SL.gif'),
                   require('../assets/work/IntroduceGoogleAdMobster/014_GAM_Admobster_Carousel_Marty_SL.gif'),
                   require('../assets/work/IntroduceGoogleAdMobster/014_GAM_Admobster_Carousel_Dawn_SL.gif')
                   ]
    },
    {
        id: 9,
        title: "Google Cloud Parnter Holidays",
        url: "GoogleCloudParnterHolidays",
        homeThumbnail: null,
        thumbnail: require('../assets/work/GoogleCloudParnterHolidays/thumbnail_4x3.png'),
        header: [require('../assets/work/GoogleCloudParnterHolidays/header.gif')],
        credits: [
                 "Client: Google Cloud Partner",
                 "Agency: M Booth",
                 "Art Director: Taylor Baldry​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "Illustration&Animation: Sisi Li"
                 ],
        video: "https://player.vimeo.com/video/739167173?h=d92fe6693c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        images: [
                require('../assets/work/GoogleCloudParnterHolidays/032-GPT-Halloween-SL.gif'),
                require('../assets/work/GoogleCloudParnterHolidays/sketch1.png'),
                require('../assets/work/GoogleCloudParnterHolidays/sketch2.png'),
                require('../assets/work/GoogleCloudParnterHolidays/160-GPT-Thanksgiving-2021-SL-08.png')
                ]
    },
    {
        id: 10,
        title: "M Booth Emojis",
        url:"MBoothEmojis",
        thumbnail: require('../assets/work/MBoothEmojis/thumbnail.gif'),
        header: [require('../assets/work/MBoothEmojis/taped_banana_long.gif')],
        credits: [
                 "Agency: M Booth",
                 "Art Director: Linh Pham​​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "3D Designers:",
                 "Nicholas Kiray / Andie Villanueva / Sisi Li"
                 ],
        images4x3: [
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_1.png'),
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_2.png'),
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_4.png'),
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_3.png'),
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_5.png'),
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_6.png'),
                   require('../assets/work/MBoothEmojis/MB Office emojis-dribbble_7.png')
                   ]
    },
    {
        id: 11,
        title: "Champion Wheel",
        url: "ChampionWheel",
        thumbnail: require('../assets/work/ChampionWheel/thumbnail.png'),
        header: [require('../assets/work/ChampionWheel/frame1.gif')],
        credits: [
                 "Agency: M Booth",
                 "Art Director: Linh Pham​​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "Design & Animation: Sisi Li"
                 ],
        images4x3: [
                   require('../assets/work/ChampionWheel/frame2.gif'),
                   require('../assets/work/ChampionWheel/frame3.gif'),
                   require('../assets/work/ChampionWheel/frame4.gif'),
                   require('../assets/work/ChampionWheel/lockup.png')
                   ]
    },
    {
        id: 12,
        title: "Google for Education Privacy & Secruity",
        url: "GoogleforEducationPrivacy&Secruity",
        thumbnail: require('../assets/work/GDUPrivacy&Security/thumbnail.png'),
        header: [require('../assets/work/GDUPrivacy&Security/48-1.gif')],
        credits: [
                 "Agency: M Booth",
                 "Art Director: Linh Pham​​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "Design & Animation: Sisi Li"
                 ],
        images4x3: [
                   require('../assets/work/GDUPrivacy&Security/48-2-Pillar1.gif'),
                   require('../assets/work/GDUPrivacy&Security/48-3-Pillar2.gif'),
                   require('../assets/work/GDUPrivacy&Security/48-4-Pillar3.gif'),
                   require('../assets/work/GDUPrivacy&Security/48-5-Chrome.gif'),
                   require('../assets/work/GDUPrivacy&Security/48-6-Youtube.gif'),
                   require('../assets/work/GDUPrivacy&Security/48-7.gif'),
                   ],
        images2: [require('../assets/work/GDUPrivacy&Security/003-GDU[SEC] – Every Day is Safer with Google Animation-SL.gif')]
    },
    {
        id: 13,
        title: "Back to School",
        url: "BacktoSchool",
        thumbnail: require('../assets/work/BacktoSchool/thumbnail.png'),
        header: [require('../assets/work/BacktoSchool/020-B2S-Blog2-Families-SL-v1.gif')],
        credits: [
                 "Client: Google for Education",
                 "Agency: M Booth",
                 "Art Director: Linh Pham​​​​​​​​",
                 "Project Management: Kyle McEwen",
                 "Design & Animation: Sisi Li"
                 ],
        images: [
                require('../assets/work/BacktoSchool/023-B2S-Blog3-Assignment-Originality-Reports-SL-v1.gif'),
                require('../assets/work/BacktoSchool/040-B2S-Blog-4-Classroom-SL-social.gif'),
                require('../assets/work/BacktoSchool/039-B2S-Blog-1-Best-of-Back-to-School-SL-social.gif')
                ]
    },
    {
        id: 14,
        title: "7 Day Challenge",
        url: "7DayChallenge",
        thumbnail: require('../assets/work/7DayChallenge/thumbnail.png'),
        header: [require('../assets/work/7DayChallenge/header.png')],
        lockup: [require('../assets/work/7DayChallenge/lockup.gif')],
        credits: [
            "Client: Google for Education",
            "Agency: M Booth",
            "Art Director: Linh Pham​​​​​​​​",
            "Project Management: Kyle McEwen",
            "Lead Design: Sisi Li",
            "3D Designer & Animator:",
            "Sisi Li / Nicholas Kiray / Andie Villanueva"
            ],
        images: [
                require('../assets/work/7DayChallenge/176-BIA-01.png'),
                require('../assets/work/7DayChallenge/162-BIA-SID-Stickersheet.png'),
                require('../assets/work/7DayChallenge/068-BIA-SID-Flowchart_Brave.png'),
                require('../assets/work/7DayChallenge/074-SID-Kind-Flow-chart-SL.png'),
                require('../assets/work/7DayChallenge/077-SID-Brave-Flow-chart-SL.png'),
                require('../assets/work/7DayChallenge/stories.png'),
                require('../assets/work/7DayChallenge/192_BIA.gif')
                ],
        thankYou: [
                  require('../assets/work/7DayChallenge/thankYou.gif')
                  ]
    },
    {
        id: 15,
        title: "2020 Alaphabet",
        url: "2020Alaphabet",
        thumbnail: require('../assets/work/2020Alphabet/thumbnail.jpg'),
        header: [require('../assets/work/2020Alphabet/thumbnail.jpg')],
        credits: ["Personal Stickersheet Project"],
        images: [
                require('../assets/work/2020Alphabet/AlphabetG1.png'),
                require('../assets/work/2020Alphabet/AlphabetG2.png'),
                require('../assets/work/2020Alphabet/AlphabetG3.png')
                ],
        images4x3: [
                    require('../assets/work/2020Alphabet/DSC02981.jpg'),
                    require('../assets/work/2020Alphabet/DSC03003.jpg'),
                    require('../assets/work/2020Alphabet/DSC03010.jpg')
                    ]
    }
 ] 

export default Datas