import React from 'react'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import { Link } from 'react-router-dom'

import './work.css'
import { Tapes } from '../../../data/HomeData.js'

import WorkSlot from './WorkSlot.jsx'


const Work = ({Datas}) => {
  
  return (
    <ScrollContainer className='work'>
      <div className='work_section'>
        {Datas.slice(0,9).map((data) => (
          <div className={`work${data.id}`} key={data.id}>
            <img src={Tapes[data.id]} className={`tapes tape${data.id}`} />
            <Link to={`/work/${data.url}`} data={data} >
              <WorkSlot title={data.title} image={data.homeThumbnail} />
            </Link>
          </div>
        ))}
      </div>

    </ScrollContainer>
  )
}

export default Work