import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <p className='footer_copy'>sisili.tv@gmail.com</p>
    </footer>
  )
}

export default Footer