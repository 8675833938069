import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import './nav.css'

const Nav = () => {

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <nav>
        <div className='nav_text'>
          <Link to='/' className='logo'></Link>
          <div className='nav_list'>
            { location.pathname=== '/'? <a href="#play" className='nav_link' >Play</a> : <Link to='/' className='nav_link'>Play</Link> }
            <Link to='/work' className='nav_link'>Work</Link>
            <Link to='/reel'  className='nav_link'>Reel</Link>
            <Link to='/about' className='nav_link'>About</Link>
          </div>
        </div>
    </nav>
  )
}

export default Nav