import React from 'react'
import './BTN.css'

const BTN = ({text, handleClick}) => {
  return (
    <div className='button_container'>
      <button className='button click' onClick={handleClick} >{text}</button>
      <button className='button_shadow click'>{text}</button>
    </div>
  )
}

BTN.defaultProps =  { text: 'Button' };

export default BTN