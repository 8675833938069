
const PlayVideo = {
    ready: require('../assets/Play_ready.gif'),
    loop: require('../assets/Play_loop.gif'),
    catch: require('../assets/Play_catching.gif')
}

const Butterflies = require('../assets/Butterflies.gif')

const BgAudio = require('../assets/mixkit-forest-with-birds-and-insect-flying-hum-1223.mp3')

const Tapes = [
    require('../assets/work/tapes/tape0.svg').default,
    require('../assets/work/tapes/tape1.svg').default,
    require('../assets/work/tapes/tape2.svg').default,
    require('../assets/work/tapes/tape3.svg').default,
    require('../assets/work/tapes/tape4.svg').default,
    require('../assets/work/tapes/tape5.svg').default,
    require('../assets/work/tapes/tape6.svg').default,
    require('../assets/work/tapes/tape7.svg').default,
    require('../assets/work/tapes/tape8.svg').default
]

const LoadingGIF = require('../assets/loading.json')

export { PlayVideo, Butterflies, BgAudio, Tapes, LoadingGIF }
