import React from 'react';
import { Link } from 'react-router-dom'

import '../../components/caseStudy/caseStudy.css'

const CaseStudy = ({Datas}) => {
    
    document.body.style.backgroundColor = '#ffffff'

    return (
        <section className='firstSection case_study' >
            <div className='case_studies' >
                {Datas.slice(1,16).map((data) => (
                    <Link to={`/work/${data.url}`} key={data.id} className='work_preview'>
                        <img src={data.thumbnail} className='work_thumbnail' />
                        <h3 className='work_title'>{data.title}</h3>
                    </Link>
                    
                ))}
            </div>
        </section>
    );
};

export default CaseStudy;