import React from 'react'
import './about.css'

import Headshot from '../../../assets/Sisi_Head_Woo.png'
import Debbie from '../../../assets/Debbie_Head.png'

const About = () => {
  return (
    <section className='about' id='about'>
      <div className='chat_box'>
        <img src={ Headshot } className='headshot' alt='headshot' />
        <h1 className='about_copy'> Hi there, I'm Sisi Li :)
          <br></br> Motion Designer based in
          <br></br> San Francisco Bay. 
         
        </h1>
      </div>
      <div className='chat_box'>
        <h1 className='debbie_copy'> Woof! Let's catch
            <br></br> fun projects together 🦋
        </h1>
        <img src={ Debbie } className='debbie' alt='Debbie' />
      </div>
    </section>
  )
}

export default About
