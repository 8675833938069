import React from 'react';

import Play from './play/Play.jsx'
import Work from './work/Work.jsx'
import Reel from './reel/Reel.jsx'
import About from './about/About.jsx'

const Home = ({Datas}) => {

    document.body.style.backgroundColor = '#FBF4EB';

    return (
        <div className='home firstSection'>
            <Play />
            <Work Datas={Datas}/>
            <Reel />
            <About />
        </div>
    );
};

export default Home;