import React from 'react'
import { useParams } from 'react-router-dom'

import './project.css'


const Project = ({Datas}) => {
    const { url } = useParams();
    document.body.style.backgroundColor = '#ffffff'

    return (
        <section className='project firstSection'>
            {Datas
              .filter(data => data.url === url)
              .map((data) => (
                    <div key={data.id} className='project_container' >

{/*-----------------------------------header-------------------------------------*/}                     
                        {data.header.map((header,index)=> (
                            <img src={data.header[index]} className='project_image' />
                        ))}

{/*-----------------------------------title-------------------------------------*/}                          
                        {data.lockup !== null? <img src={data.lockup} className='project_lockup' /> : null}
                        <h1 className='project_title'> {data.title} </h1>
                        <div className='credits'>
                            {data.credits.map((credit,index) => (
                              <h4 className='credit'>{data.credits[index]}</h4>
                            ))}
                        </div>

{/*-----------------------------------video-------------------------------------*/}   
                        {data.video?
                            <iframe
                            src={data.video}
                            title={data.title}
                            className='project_video'
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen>
                            </iframe> : null
                        }
                        {data.videoV?
                            <iframe
                            src={data.videoV}
                            title={data.title}
                            className='project_videoV'
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen>
                            </iframe> : null
                        }

{/*-----------------------------------image-------------------------------------*/}
                        {data.subTitle1? <h1 className='project_title'>{data.subTitle1}</h1> : null }  
                        {data.images?
                            data.images.map((image, index) => (
                                <img src={data.images[index]} className='project_image' />
                            )) : null
                        }
                        {data.images4x3?
                            data.images4x3.map((image, index) => (
                                <img src={data.images4x3[index]} className='project_image4x3' />
                            )) : null
                        } 

                        {data.subTitle2? <h1 className='project_title'>{data.subTitle2}</h1> : null }
                        {data.images2?
                            data.images2.map((image, index) => (
                                <img src={data.images2[index]} className='project_image' />
                            )) : null
                        }   

{/*-----------------------------------thankYou-------------------------------------*/}  
                        {data.videoF?
                            <iframe
                            src={data.videoF}
                            title={data.title}
                            className='project_video'
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen>
                            </iframe> : null
                        }
                        {data.thankYou?
                            data.thankYou.map((image, index) => (
                                <img src={data.thankYou[index]} className='thankYou' />
                            )) : null
                        }

                    </div>
            ))}
        </section>
    );
};

export default Project;