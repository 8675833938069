import React from 'react'
import Lottie from "lottie-react";

import './preloader.css'
import {LoadingGIF, Butterflies} from '../../../data/HomeData.js'

const Preloader = () => {
  return (
    <div className="loading">
        <Lottie
          animationData={LoadingGIF}
          loop={true}
          className="loadingGIF"
        />
        <img src={Butterflies} className="butterflies"></img>
    </div>
  )
}

export default Preloader